import { BillingPriceResponseData } from "@models/api";

export enum CompanySubview {
  Entitlements = "entitlements",
  Usage = "usage",
  Users = "users",
  // CRM = "crm",
  Checks = "checks",
  Subscription = "subscription",
}

export type AddOnPriceWithAddOnName = BillingPriceResponseData & {
  addOnName: string;
  addOnId: string;
  billingProductId: string;
};

export enum ProductPriceInterval {
  Month = "month",
  Year = "year",
}
