import { FormikControl } from "@forms/FormikControl";
import { useCurrentEnvironment } from "@hooks/useCurrentEnvironment";
import { CreateCompanyOverrideFormValues } from "@modules/features/components/overlays/CompanyOverrideOverlay/CompanyOverrideOverlay";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { FormRow } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Switch } from "@ui/Switch";
import { useFormikContext } from "formik";
import { Link } from "react-router-dom";

export const OverrideExpirationBlock = () => {
  const { values, setFieldValue } =
    useFormikContext<CreateCompanyOverrideFormValues>();

  const isEnabled = useSchematicFlag("timed-override");

  const { environment } = useCurrentEnvironment();
  const managePlanUrl = `/${environment?.id}/settings/plan`;

  return (
    <>
      {values.feature && values.company && (
        <>
          <div className="h-px w-full bg-gray-300 my-12" />

          <FormRow className="px-12 justify-between items-center">
            <div className="flex flex-col w-full">
              <div className="flex">
                <Switch
                  disabled={!isEnabled}
                  name="expirationEnabled"
                  checked={!!values.expirationEnabled}
                  onCheckedChange={async (checked) => {
                    await setFieldValue("expirationEnabled", checked);
                  }}
                />
                <div className="flex flex-col justify-between ml-4">
                  <div className="flex items-center">
                    <Icon
                      name="watch-pocket"
                      className="text-4xl text-gray-500 leading-none mr-1"
                    />
                    <h4 className="text-xl font-medium">Expiration date</h4>
                  </div>
                  <p className="ml-2">
                    Set a date when this override will expire.
                  </p>
                </div>
                {!isEnabled && (
                  <div className="items-top flex-grow text-right text-xs">
                    <span className="text-gray-500">Not in your plan. </span>
                    <Link
                      to={managePlanUrl}
                      className="text-blue-500 cursor-pointer"
                    >
                      Manage plan
                    </Link>
                  </div>
                )}
              </div>
              {values.expirationEnabled && (
                <FormikControl
                  control="date"
                  name="expirationDate"
                  label="Active through date (UTC)"
                  minDate={new Date().setDate(new Date().getDate() + 1)}
                  dateFormat="MMM dd, yyyy"
                  className="mt-4 w-full"
                />
              )}
            </div>
          </FormRow>
        </>
      )}
    </>
  );
};
