import { EnvironmentOverlayForm } from "@components/overlays/EnvironmentOverlayForm";
import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import { useRole } from "@hooks/useRole";
import { AccountEnvironment } from "@models/account";
import { ClerkUserRole } from "@models/clerkUser";
import { Button } from "@ui/Button";
import { Icon } from "@ui/Icon";
import { Overlay } from "@ui/Overlay";
import { Pill, PillStyleTypes } from "@ui/Pill";
import cx from "classnames";
import { useState } from "react";
import SchematicOverlayLoader from "../loaders/SchematicOverlayLoader";

export interface EnvironmentOverlayProps {
  environments: AccountEnvironment[];
  overlayEnvironment: any;
  setOverlayEnvironment: (environment: AccountEnvironment | undefined) => void;
  onCreate: (environment: AccountEnvironment) => void;
  onDelete: (environment: AccountEnvironment) => Promise<void>;
  onClose: () => void;
}

export const EnvironmentOverlay = ({
  environments,
  overlayEnvironment,
  setOverlayEnvironment,
  onClose,
  onCreate,
  onDelete,
}: EnvironmentOverlayProps) => {
  const [loading, setLoading] = useState(false);

  const environmentEditAllowed = useRole(ClerkUserRole.Admin);
  const handleCreateEnvironment = () => {
    setOverlayEnvironment(undefined);
  };
  return (
    <Overlay
      onClose={onClose}
      className="flex  items-center justify-center py-24"
    >
      {loading && <SchematicOverlayLoader />}
      <div className="relative bg-white rounded-lg overflow-hidden shadow-lg min-w-[900px] max-w-[900px]">
        <span
          onClick={onClose}
          className="opacity-60 hover:opacity-100 cursor-pointer absolute right-4 top-4"
        >
          <Icon name="close" className="text-2xl" />
        </span>
        <div className="flex">
          <div className="flex flex-col bg-blue-50/70 border-r border-blue-100/40">
            <div className="flex space-x-8 items-center p-8 pb-6">
              <div className="whitespace-nowrap font-medium text-center text-[1.25rem]">
                {environments.length} Environments
              </div>
              <div className="overflow-hidden">
                <Button
                  onClick={handleCreateEnvironment}
                  disabled={!environmentEditAllowed || !overlayEnvironment}
                  className="cursor-pointer text-center whitespace-nowrap"
                  color="blue"
                  size="sm"
                >
                  Create
                </Button>
              </div>
            </div>
            <div className="max-h-[580px] flex flex-col overflow-hidden overflow-y-auto">
              {environments.map((env) => {
                const styles = cx(
                  "px-8 py-3 border-l-[3px] cursor-pointer font-medium hover:bg-blue-100/40",
                  env.id == overlayEnvironment?.id
                    ? "border-l-blue-400 bg-gray-300/70"
                    : "border-l-transparent",
                );

                return (
                  <div
                    className={styles}
                    key={env.id}
                    onClick={() => setOverlayEnvironment(env)}
                  >
                    <div className="flex justify-between items-center w-full">
                      <div
                        className={cx(
                          "flex-1 ",
                          env.id == overlayEnvironment?.id ? "font-bold" : "",
                        )}
                      >
                        {env.name}
                      </div>
                      <Pill
                        color={
                          environmentPillColors[
                            env.environmentType
                          ] as PillStyleTypes
                        }
                      >
                        {EnvironmentTypeShort[env.environmentType]}
                      </Pill>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <EnvironmentOverlayForm
            environment={overlayEnvironment}
            onDelete={onDelete}
            onCreate={onCreate}
            setLoading={setLoading}
            onClose={onClose}
          />
        </div>
      </div>
    </Overlay>
  );
};
