import { useUser } from "@clerk/clerk-react";
import { EnvironmentOverlay } from "@components/overlays/EnvironmentOverlay";
import { environmentPillColors } from "@components/overlays/types";
import { EnvironmentTypeShort } from "@consts/environments";
import * as api from "@data/environments";
import { useResponsiveness } from "@hooks/useResponsiveness";
import { Account, AccountEnvironment } from "@models/account";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { Icon } from "@ui/Icon";
import { Logo } from "@ui/Logo";
import { Pill, PillStyleTypes } from "@ui/Pill";

import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ButtonGroup } from "../ButtonGroup";

interface DropdownEnvironmentProps {
  account: Account;
  handleEnvironmentChange: (environment: AccountEnvironment) => void;
  selectedEnvironment: AccountEnvironment;
  environmentScoped?: boolean;
}

export const DropdownEnvironment = ({
  account,
  handleEnvironmentChange,
  selectedEnvironment,
  environmentScoped = true,
}: DropdownEnvironmentProps) => {
  const { isLarge } = useResponsiveness();
  const { user } = useUser();
  const [logoUrl, setLogoUrl] = useState<string | undefined>(undefined);
  const [envOverlay, setEnvOverlay] = useState(false);
  const [currentEnvironment, setCurrentEnvironment] =
    useState<AccountEnvironment>(selectedEnvironment);

  const [overlayEnvironment, setOverlayEnvironment] = useState<
    AccountEnvironment | undefined
  >(currentEnvironment);

  const handleOverlayCreate = (environment: AccountEnvironment) => {
    setOverlayEnvironment(environment);
    setCurrentEnvironment(environment);
    handleEnvironmentChange(environment);
  };

  const handleOverlayDelete = async (environment: AccountEnvironment) => {
    await api.deleteEnvironment(environment.id);
    setOverlayEnvironment(undefined);
  };

  const handleOverlayOpen = () => setEnvOverlay(true);
  const handleOverlayClose = () => setEnvOverlay(false);

  useEffect(() => {
    setCurrentEnvironment(selectedEnvironment);
  }, [selectedEnvironment]);

  useEffect(() => {
    if (
      user &&
      user.organizationMemberships.length > 0 &&
      user.organizationMemberships[0].organization.imageUrl
    ) {
      setLogoUrl(user.organizationMemberships[0].organization.imageUrl);
    }
  }, [user]);

  return environmentScoped ? (
    <div className="min-w-[224px]">
      <div className="h-11 w-11 flex items-center justify-center">
        <Logo
          size={!isLarge ? "sm" : "lg"}
          src={logoUrl}
          alt={account.name}
          className="w-full h-full"
        />
      </div>
    </div>
  ) : (
    <>
      <DropdownMenu.Root>
        <DropdownMenu.Trigger
          asChild
          className="rounded-[10px] flex items-center cursor-pointer"
        >
          <button aria-label="Select environment" className="">
            <div className="h-11 w-11 flex items-center justify-center">
              <Logo
                size={!isLarge ? "sm" : "lg"}
                src={logoUrl}
                alt={account.name}
                className="w-full h-full"
              />
            </div>
            <span className="font-body flex flex-col items-start ml-2 mr-3">
              <span className="text-sm lg:text-base leading-none font-medium mr-2">
                {account.name}
              </span>
              <div className="space-x-2 flex items-center">
                <div className="text-sm text-gray-400/80">
                  {currentEnvironment.name}
                </div>
                <Pill
                  className="ml-0 !p-0.5 !px-1.5 !text-xs"
                  color={
                    environmentPillColors[
                      currentEnvironment.environmentType
                    ] as PillStyleTypes
                  }
                >
                  {EnvironmentTypeShort[currentEnvironment.environmentType]}
                </Pill>
              </div>
            </span>
            <Icon name="collapse-empty" className="text-gray-400/50 text-2xl" />
          </button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <DropdownMenu.Content
            side="bottom"
            align="start"
            className="border border-gray-300/80 bg-white pt-2 pb-2 shadow-md rounded-lg w-full mt-2 flex flex-col space-y-2 min-w-[340px] cursor-pointer relative"
          >
            <div className=" max-h-[16.5rem] overflow-hidden overflow-y-auto">
              {account.environments.map((environment: AccountEnvironment) => (
                <DropdownMenu.Item
                  className="flex justify-between px-4 py-2.5 bg-transparent hover:bg-gray-100 !outline-none transition-all"
                  key={environment.id}
                  onSelect={() => {
                    handleEnvironmentChange(environment);
                  }}
                >
                  <div className="flex items-center space-x-2">
                    <div className="font-medium leading-none mr-4">
                      {environment.name}
                    </div>
                  </div>
                  <div className="flex items-center">
                    <Pill
                      color={
                        environmentPillColors[
                          environment.environmentType
                        ] as PillStyleTypes
                      }
                    >
                      {EnvironmentTypeShort[environment.environmentType]}
                    </Pill>
                  </div>
                </DropdownMenu.Item>
              ))}
            </div>
            <DropdownMenu.Item className="outline-none cursor-default">
              <div className="px-2">
                <ButtonGroup
                  className="!space-x-2 flex flex-row text-center"
                  buttons={[
                    {
                      children: "Create",
                      className: "flex-1",
                      color: "blue",
                      size: "sm",
                      onClick: () => {
                        handleOverlayOpen();
                        setOverlayEnvironment(undefined);
                      },
                    },
                    {
                      children: "Manage",
                      className: "flex-1",
                      size: "sm",
                      onClick: handleOverlayOpen,
                    },
                  ]}
                />
              </div>
            </DropdownMenu.Item>
          </DropdownMenu.Content>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>

      {envOverlay &&
        createPortal(
          <EnvironmentOverlay
            environments={account.environments}
            overlayEnvironment={overlayEnvironment}
            setOverlayEnvironment={setOverlayEnvironment}
            onCreate={handleOverlayCreate}
            onDelete={handleOverlayDelete}
            onClose={handleOverlayClose}
          />,
          document.body!,
        )}
    </>
  );
};
