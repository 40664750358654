import { usePermission } from "@hooks/usePermission";
import { ClerkUserPermission } from "@models/clerkUser";
import { useSchematicEntitlement } from "@schematichq/schematic-react";

interface FeatureEntitlement {
  canCreate: boolean;
  canEdit: boolean;
  canView: boolean;
  featureUsage: number | undefined;
  featureAllocation: number | undefined;
}

export const useSchematicFeature = (
  feature: string,
  options: { fallback?: boolean; editPermission?: ClerkUserPermission } = {
    fallback: false,
  },
): FeatureEntitlement => {
  const { editPermission, fallback } = options;
  const entitlement = useSchematicEntitlement(feature, { fallback });
  const canEdit = usePermission(editPermission ?? ClerkUserPermission.none);

  return {
    // for features/entitlements, the flag value represents the ability to consume more of the resource (i.e., create)
    canCreate: entitlement.value && canEdit,

    // canEdit is determine only by the permission; flags/entitlements do not affect this
    canEdit,

    // company can view feature if the flag's value is true, or if the flag is false but the feature usage has been exceeded
    // featureUsageExceeded will be undefined if there's no usage being measure (eg not an entitlement rule, etc); in this case, we just want to treat it as false here.
    canView: entitlement.value || !!entitlement.featureUsageExceeded,

    // the underlying entitlement feature usage
    featureUsage: entitlement.featureUsage,

    // the underlying entitlement feature allocation
    featureAllocation: entitlement.featureAllocation,
  };
};
