import { ClipCopy } from "@components/ui/ClipCopy";
import { Overlay, OverlayHeader, OverlayModal } from "@components/ui/Overlay";
import { OverlayLoader } from "@components/ui/Overlay/OverlayLoader";
import * as api from "@data/events";
import { useContextQuery } from "@hooks/useContextQuery";
import { EventStatus } from "@models/events";
import { LabelData, LabelDataGrid } from "@ui/LabelData";
import { Pill } from "@ui/Pill";
import { PillGroup } from "@ui/PillGroup";
import { TimelineItem, TimelineList } from "@ui/TimelineList";
import { pillColorEventStatus } from "../helpers";

interface EventOverlayProps {
  id: string;
  onClose: () => void;
}

export const EventOverlay = ({ id, onClose }: EventOverlayProps) => {
  const { isLoading, error, data } = useContextQuery({
    queryKey: [`event`, id],
    queryFn: () => api.getEvent(id),
  });

  if (error) throw error;

  return (
    <Overlay onClose={onClose}>
      <OverlayModal position="right">
        {isLoading || !data ? (
          <OverlayLoader />
        ) : (
          <>
            <OverlayHeader title="Events" onClose={onClose} />
            <div className="px-12 pt-8">
              <LabelDataGrid>
                <LabelData label="Event">
                  <Pill text="code">{data.subtype || data.type}</Pill>
                </LabelData>
                <LabelData label="Unique Key">
                  <ClipCopy
                    data={data.id}
                    truncate
                    className="max-w-full"
                    title={data.id}
                  />
                </LabelData>
                {data.company && (
                  <LabelData label="Company">{data.company.name}</LabelData>
                )}
                {data.apiKey && (
                  <LabelData label="API Key">
                    <ClipCopy
                      data={data.apiKey}
                      className="max-w-full"
                      title={data.apiKey}
                    />
                  </LabelData>
                )}
                <LabelData label="Status">
                  <Pill
                    color={pillColorEventStatus(data.status as EventStatus)}
                  >
                    {data.status}
                  </Pill>
                </LabelData>
                {data.quantity > 1 && (
                  <LabelData label="Quantity">{data.quantity}</LabelData>
                )}
                {data.features.length > 0 && (
                  <LabelData label="Features">
                    <PillGroup items={data.features} />
                  </LabelData>
                )}
                {data.errorMessage && (
                  <LabelData label="Error">{data.errorMessage}</LabelData>
                )}
              </LabelDataGrid>
            </div>

            <div className="px-12 pt-8">
              <LabelData label="Timeline" />
              <TimelineList>
                {data.sentAt && (
                  <TimelineItem label="Event sent" date={data.sentAt} />
                )}
                {data.capturedAt && (
                  <TimelineItem label="Event ingested" date={data.capturedAt} />
                )}
                {data.enrichedAt && data.company && (
                  <TimelineItem
                    label={
                      <>
                        Attributed to company{" "}
                        <span className="font-medium">{data.company.name}</span>
                      </>
                    }
                    date={data.enrichedAt}
                  />
                )}
                {data.enrichedAt && data.user && (
                  <TimelineItem
                    label={
                      <>
                        Attributed to user{" "}
                        <span className="font-medium">{data.user.name}</span>
                      </>
                    }
                    date={data.enrichedAt}
                  />
                )}
              </TimelineList>
            </div>
            <div className="px-12 pt-8">
              <LabelData label="Data" />
              <div className="border-gray-100 border rounded">
                <div className="bg-gray-50 p-4">
                  <pre className="overflow-scroll">
                    <ClipCopy
                      data={JSON.stringify(data.body, null, 2)}
                      position="bottom-right"
                    />
                  </pre>
                </div>
              </div>
            </div>
          </>
        )}
      </OverlayModal>
    </Overlay>
  );
};
