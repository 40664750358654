import { TableLoader } from "@components/loaders/TableLoader";
import { Alert } from "@components/ui/Alert";
import { DropdownDots } from "@components/ui/DropdownDots";
import { Pill } from "@components/ui/Pill";
import { usePermission } from "@hooks/usePermission";
import { useRole } from "@hooks/useRole";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import useTablePagination from "@hooks/useTablePagination";
import { ListPlansParams } from "@models/api";
import { ClerkUserPermission, ClerkUserRole } from "@models/clerkUser";
import { Plan, PlanType } from "@models/plan";
import { FeaturesTableEmptyFilterState } from "@modules/features";
import { countPlans, listPlans } from "@modules/plans";
import { PlanRoutePaths } from "@modules/plans/consts";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { CellContext, ColumnDef, VisibilityState } from "@tanstack/react-table";
import { ButtonProps } from "@ui/Button";
import { Diamond, DiamondStyleTypes } from "@ui/Diamond";
import { PillGroup } from "@ui/PillGroup";
import { Table } from "@ui/Table";
import { TableHeader } from "@ui/TableHeader";
import { titlecase } from "@utils/strings";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useParams } from "react-router-dom";
import { AddOnsBlankState } from "../blank-states/AddOnsBlankState";
import { PlansBlankState } from "../blank-states/PlansBlankState";
import { PlanDeleteOverlay } from "../overlays";
import { PriceInfoBlock } from "../PriceInfoBlock";

export interface PlansTableProps {
  type: PlanType;
  onCreate?: () => void;
}

export const PlansTable = ({ type, onCreate }: PlansTableProps) => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const queryClient = useQueryClient();
  const billingFlag = useSchematicFlag("billing", { fallback: true });
  const planConfigurationFlag = useSchematicFlag("plan-configuration", {
    fallback: true,
  });

  const [searchTerm, setSearchTerm] = useState("");
  const [filter, setFilter] = useState<ListPlansParams>({});
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const planEditPermission = usePermission(ClerkUserPermission.plans_edit);
  const adminRole = useRole(ClerkUserRole.Admin);
  const planEditAllowed = adminRole ?? planEditPermission;
  const [deletePlan, setDeletePlan] = useState<Plan | undefined>();
  const [successAlert, setSuccessAlert] = useState("");

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setFilter(searchTerm === "" ? {} : { q: searchTerm });
  }, [searchTerm]);

  const noun = type === PlanType.Plan ? "plan" : "add on";

  const columns = useMemo<ColumnDef<Plan>[]>(() => {
    return [
      {
        id: "plan",
        header: noun,
        accessorKey: "plan",
        size: 300,
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <div className="flex flex-row items-center">
              <Diamond style={cell.icon as DiamondStyleTypes} />
              <div className="flex flex-col ml-4">
                <div
                  className="font-medium text-[18px] !leading-none truncate-md"
                  title={cell.name}
                >
                  {cell.name}
                </div>
                <div className="leading-none text-sm text-gray-400">
                  {cell.id}
                </div>
              </div>
            </div>
          );
        },
      },
      {
        id: "features",
        header: "Features",
        accessorKey: "features",
        cell: (cellInfo) => {
          const { features } = cellInfo.row.original;

          return (
            <div className="flex flex-row items-center">
              <PillGroup items={features} limit={1} />
            </div>
          );
        },
      },
      ...(billingFlag
        ? [
            {
              id: "prices",
              header: "Prices",
              accessorKey: "prices",
              cell: (cellInfo: CellContext<Plan, unknown>) => {
                const plan = cellInfo.row.original;

                return (
                  <div className="flex">
                    <PriceInfoBlock
                      planId={plan.id}
                      items={plan.billingProduct?.prices || []}
                    />
                    {plan.isTrialable && (
                      <Pill
                        text="normal"
                        type="rounded"
                        className="!px-3 ml-2 text-sm"
                      >
                        Trial supported
                      </Pill>
                    )}
                  </div>
                );
              },
            },
          ]
        : []),
      {
        id: "companies",
        header: "Companies",
        accessorKey: "companies",
        size: 160,
        cell: (cellInfo) => {
          const cell = cellInfo.row.original;
          return (
            <div className="leading-none text-gray-400">
              {cell.companyCount}
            </div>
          );
        },
      },
      {
        id: "actions",
        header: "",
        accessorKey: "actions",
        cell: (cellInfo) => {
          const plan = cellInfo.row.original;
          return (
            <div className="flex flex-row items-end justify-end relative z-10">
              <DropdownDots
                color="gray"
                links={[
                  {
                    label: `Delete ${noun}`,
                    disabled: !planEditAllowed,
                    onClick: () => {
                      setSuccessAlert("");
                      setDeletePlan(plan);
                    },
                  },
                ]}
              />
            </div>
          );
        },
      },
    ];
  }, [billingFlag, type]);

  const {
    countQuery,
    listQuery,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = useTablePagination<Plan, ListPlansParams>(
    ["plans", type],
    listPlans(type),
    countPlans(type),
    filter,
  );

  const plansHeaderText = useSecondaryTableHeader(
    "plans",
    countPlans(PlanType.Plan),
  );
  const addonsHeaderText = useSecondaryTableHeader(
    "add ons",
    countPlans(PlanType.AddOn),
  );

  const detailsLink = (row: Plan) => {
    return `${
      type === PlanType.Plan ? PlanRoutePaths.Plans : PlanRoutePaths.AddOns
    }/${row.id}`;
  };

  const onDelete = useCallback(() => {
    queryClient.invalidateQueries();

    setSuccessAlert(`${titlecase(noun)} deleted successfully`);
    setDeletePlan(undefined);
  }, [queryClient]);

  if (listQuery.error) throw listQuery.error;
  if (countQuery.error) throw countQuery.error;

  const headerButtons: ButtonProps[] = [
    {
      children: <>Create {noun}</>,
      color: "blue",
      disabled:
        !useSchematicFlag("plans", { fallback: true }) || !planEditAllowed,
      onClick: onCreate,
    },
  ];

  const noPlansCreated = countQuery?.data?.count === 0 && searchTerm === "";
  const isAddon = type === PlanType.AddOn;

  const loading =
    listQuery.isLoading || countQuery.isLoading || !listQuery.data;

  const renderPlansTable = () => {
    switch (true) {
      case noPlansCreated:
        return isAddon ? (
          <AddOnsBlankState onCreate={onCreate} />
        ) : (
          <PlansBlankState onCreate={onCreate} disabled={!planEditAllowed} />
        );
      case loading:
        return <TableLoader />;
      case listQuery.data?.length === 0:
        return <FeaturesTableEmptyFilterState />;
      default:
        return (
          listQuery?.data && (
            <Table
              columns={columns}
              data={listQuery.data}
              detailsLink={detailsLink}
              pageCount={pageCount}
              pageIndex={pageIndex}
              pageSize={pageSize}
              setPagination={setPagination}
              columnVisibility={columnVisibility}
              setColumnVisibility={setColumnVisibility}
            />
          )
        );
    }
  };

  return (
    <div className="pb-16">
      {successAlert && (
        <div className="mb-4">
          <Alert style="green" size="xs" className="flex">
            {successAlert}
          </Alert>
        </div>
      )}

      <TableHeader
        headerTabs={[
          {
            active: type === PlanType.Plan,
            label: plansHeaderText,
            url: `/${environmentId}/${PlanRoutePaths.Plans}`,
          },
          {
            active: type === PlanType.AddOn,
            label: addonsHeaderText,
            url: `/${environmentId}/${PlanRoutePaths.AddOns}`,
          },
          ...(planConfigurationFlag
            ? [
                {
                  label: "Configuration",
                  url: `/${environmentId}/${PlanRoutePaths.Configuration}`,
                },
              ]
            : []),
        ]}
        searchPlaceholder={`Find ${type === PlanType.Plan ? "Plan" : "Add On"}`}
        onSearch={handleSearch}
        buttons={headerButtons}
        className="bg-[#FBFBFB] sticky top-[-3rem] left-0 z-20"
      />

      {renderPlansTable()}

      {deletePlan &&
        createPortal(
          <PlanDeleteOverlay
            plan={deletePlan}
            onClose={() => setDeletePlan(undefined)}
            onDelete={onDelete}
          />,
          document.body,
        )}
    </div>
  );
};
