import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { AccountEnvironment } from "@models/account";
import { ClerkUserPermission, ClerkUserRole } from "@models/clerkUser";
import { UpgradeCtaOverlay } from "@modules/integrations/components/overlays/UpgradeCtaOverlay";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Overlay, OverlayHeader, OverlayModal } from "@ui/Overlay";
import { Separator } from "@ui/Separator";
import cx from "classnames";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { TeammateEnvironmentPermissions } from "./TeammateEnvironmentPermissions";
import { inviteTeammate, updateTeammate } from "../../../queries/team";

const validationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  role: Yup.mixed<ClerkUserRole>()
    .oneOf(Object.values(ClerkUserRole))
    .required("Role is required"),
});

export type TeammateData = {
  userId?: string;
  firstName: string;
  lastName: string;
  email: string;
  role: ClerkUserRole;
  permissions: Record<string, Record<ClerkUserPermission, boolean>>;
};

export type TeammateServerData = {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  role: ClerkUserRole;
  permissions: Record<string, ClerkUserPermission[]>;
};

interface AddTeammateOverlayProps {
  teammate?: TeammateServerData;
  environments: AccountEnvironment[];
  onClose: () => void;
}

// TODO: Think about env deletion
const setPermissions = (existingPermissions?: ClerkUserPermission[]) => {
  const permissions = Object.values(ClerkUserPermission).reduce(
    (acc, permission) => {
      acc[permission] = false;
      return acc;
    },
    {} as Record<ClerkUserPermission, boolean>,
  );

  if (existingPermissions) {
    existingPermissions.forEach((permission) => {
      permissions[ClerkUserPermission[permission]] = true;
    });
  }

  return permissions;
};

export const TeammateOverlay = ({
  teammate,
  environments,
  onClose,
}: AddTeammateOverlayProps) => {
  const [loading, setLoading] = useState(false);

  const [apiError, setApiError] = useState<string | undefined>();
  const initialValues = {
    userId: teammate?.userId || undefined,
    firstName: teammate?.firstName || "",
    lastName: teammate?.lastName || "",
    email: teammate?.email || "",
    role: teammate?.role || ClerkUserRole.Member,
    permissions: {
      ...environments.reduce((acc, env) => {
        acc[env.id] = setPermissions(teammate && teammate.permissions[env.id]);

        return acc;
      }, {} as Record<string, Record<ClerkUserPermission, boolean>>),
    },
  } as TeammateData;

  const onSubmit = async (values: TeammateData) => {
    setLoading(true);

    try {
      const reqBody = {
        ...values,
        permissions: Object.entries(values.permissions).reduce(
          (acc, [envApiId, value]) => {
            acc[envApiId] = Object.entries(value)
              .filter(([, value]) => value)
              .map(([key]) => key as ClerkUserPermission);

            return acc;
          },
          {} as Record<string, ClerkUserPermission[]>,
        ),
      };

      if (reqBody.userId) {
        await updateTeammate({ ...reqBody, userId: reqBody.userId });
      } else {
        await inviteTeammate(reqBody);
      }

      setApiError(undefined);
      setLoading(false);

      onClose();
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      setLoading(false);
    }
  };

  const isTeammatesEnabled = useSchematicFlag("teammates");

  return (
    <Overlay onClose={onClose}>
      {loading && <SchematicOverlayLoader className="!z-[500] fixed" />}
      <OverlayModal size="lg">
        <OverlayHeader title="Add teammate" onClose={onClose} />
        <div className="flex-1 h-full max-h-[700px] max-w-full overflow-hidden overflow-y-scroll relative">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {(form) => {
              return (
                <Form>
                  <FormColumn className="px-12">
                    <FormRow className="">
                      <div className="flex-1">
                        <FormikControl
                          control="input"
                          label="First name"
                          name="firstName"
                          placeholder="Enter first name"
                          type="text"
                        />
                      </div>

                      <div className="flex-1">
                        <FormikControl
                          control="input"
                          label="Last name"
                          name="lastName"
                          placeholder="Enter last name"
                          type="text"
                        />
                      </div>
                    </FormRow>

                    <FormRow>
                      <div className="flex-1">
                        <FormikControl
                          control="input"
                          label="Email"
                          name="email"
                          placeholder="Enter email"
                          type="email"
                          disabled={teammate}
                        />
                      </div>
                      <div className="flex-1">
                        <FormikControl
                          control="select"
                          label="Role"
                          name="role"
                          placeholder="Select role"
                          options={[
                            {
                              value: ClerkUserRole.Admin,
                              label: "Admin",
                            },
                            {
                              value: ClerkUserRole.Member,
                              label: "Member",
                            },
                          ]}
                        />
                      </div>
                    </FormRow>

                    {apiError && (
                      <Alert size="xs" style="red">
                        <span className="font-semibold">Uh-oh!</span> {apiError}
                      </Alert>
                    )}
                  </FormColumn>

                  {form.values.role !== ClerkUserRole.Admin && (
                    <div className="mx-12">
                      <Separator type="normal" className="-mx-12 my-12" />

                      <div className="relative -m-2">
                        {!isTeammatesEnabled && (
                          <UpgradeCtaOverlay
                            title="Upgrade to use Role Based Access Control"
                            size="narrow"
                          />
                        )}
                        <div className="p-2">
                          <div className="flex flex-col">
                            <div className="capitalize text-xl font-normal mb-6 flex-1">
                              Environments
                            </div>
                            <div className="space-y-4">
                              {environments.map((env, index) => {
                                return (
                                  <TeammateEnvironmentPermissions
                                    key={env.id}
                                    env={env}
                                    form={form}
                                    permissions={
                                      form.values.permissions[env.id]
                                    }
                                    defaultOpen={index === 0}
                                  />
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={cx(
                      "sticky bottom-0 bg-w flex items-end w-full flex-1 space-x-2 mt-12 justify-end",
                      "px-12 pb-12 pt-8 w-full",
                      "bg-gradient-to-t via-white from-white to-transparent",
                    )}
                  >
                    <Button
                      type="submit"
                      color="blue"
                      disabled={!form.dirty || !isTeammatesEnabled}
                    >
                      {teammate ? "Save" : "Send invite"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </OverlayModal>
    </Overlay>
  );
};
