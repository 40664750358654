import { useNavigateEnvironment } from "@hooks/useNavigateEnvironment";
import { Button, ButtonSizeTypes } from "@ui/Button";

export const UpgradeCtaOverlay = ({
  title = "Upgrade to get started",
  size = "lg",
}: {
  title: string;
  size?: "lg" | "sm" | "narrow";
}) => {
  const navigate = useNavigateEnvironment();

  const containerStyleMap = {
    sm: "flex items-center justify-center",
    lg: "flex flex-col",
    narrow: "flex flex-col",
  };

  const boxStyleMap = {
    sm: "py-4 px-8",
    lg: "mt-24 py-12 px-24",
    narrow: "mt-4 py-4 px-4",
  };

  const buttonStyleMap = {
    sm: "mt-4",
    lg: "mt-8",
    narrow: "mt-4",
  };

  const buttonSizeMap = {
    sm: "sm",
    lg: "lg",
    narrow: "sm",
  };

  return (
    <div
      className={
        "h-full w-full backdrop-blur-sm absolute z-10 " +
        containerStyleMap[size]
      }
    >
      <div
        className={
          "mx-auto bg-white border-gray-300 border rounded-lg " +
          boxStyleMap[size]
        }
      >
        <div className="flex flex-col items-center gap-2">
          <div className="text-xl">{title}</div>
          <div className="text-gray-600">
            This feature is not included in your plan
          </div>
          <Button
            className={buttonStyleMap[size]}
            color="blue"
            size={buttonSizeMap[size] as ButtonSizeTypes}
            onClick={() => navigate("settings/plan")}
          >
            Manage Plan
          </Button>
        </div>
      </div>
    </div>
  );
};
