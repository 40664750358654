import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { errorMessage } from "@data/index";
import { FormikAsyncSelect, Option } from "@forms/FormikAsyncSelect";
import { useContextQuery } from "@hooks/useContextQuery";
import {
  CompanyDetailResponseData,
  PlanGroupPlanDetailResponseData,
} from "@models/api";
import { Plan } from "@models/plan";
import {
  checkoutWithoutSubscription,
  getCheckoutData,
} from "@modules/companies/queries";
import {
  listAddOnsWithEntitlements,
  listPlansWithEntitlements,
} from "@modules/plans/queries/planConfiguration";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn, FormRow } from "@ui/FormParts";
import { Logo } from "@ui/Logo";
import {
  Overlay,
  OverlayFooter,
  OverlayHeader,
  OverlayModal,
} from "@ui/Overlay";
import { Pill } from "@ui/Pill";
import { PlanLabel } from "@ui/PlanLabel";
import { Form, Formik, FormikHelpers } from "formik";
import React, { ReactNode, useEffect, useState } from "react";
import * as Yup from "yup";

type CompanyWithoutSubscriptionManagePlanOverlayProps = {
  onClose: () => void;
  onSuccess: () => void;
  company: CompanyDetailResponseData;
};

type FormValues = {
  currentPlan?: Plan;
  selectedPlan?: Plan;
  selectedPlanId?: string;
  currentAddOns: Plan[];
  selectedAddOns: Plan[];
  selectedAddOnIds: string[];
};

type PlanOption = Option & {
  entity: PlanGroupPlanDetailResponseData;
};

type AddOnOption = {
  name: string;
  value: string;
  label: ReactNode;
  resource: Plan;
};

const AddOnOptionLabel = ({ addOn }: { addOn: Plan }) => {
  return (
    <Pill
      key={addOn.id}
      color="gray"
      type="tag"
      text="normal"
      className="text-black font-semibold font-display items-baseline"
    >
      {addOn.name}
    </Pill>
  );
};

export const CompanyWithoutSubscriptionManagePlanOverlay = ({
  onClose,
  onSuccess,
  company,
}: CompanyWithoutSubscriptionManagePlanOverlayProps) => {
  const queryClient = useQueryClient();
  const [apiError, setApiError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues | undefined>(
    undefined,
  );

  const { data: checkoutData, isLoading: isCheckoutDataLoading } =
    useContextQuery({
      queryKey: ["checkoutData", company.id],
      queryFn: () =>
        getCheckoutData({
          companyId: company.id,
        }),
      retry: false,
    });

  useEffect(() => {
    if (checkoutData) {
      const addOns = checkoutData.activeAddOns as Plan[];
      const currentPlan = checkoutData.activePlan
        ? (checkoutData.activePlan as Plan)
        : undefined;

      setInitialValues({
        currentPlan: currentPlan,
        selectedPlan: currentPlan,
        selectedPlanId: currentPlan?.id,
        currentAddOns: addOns,
        selectedAddOns: addOns,
        selectedAddOnIds: addOns.map(({ id }) => id),
      });
    }
  }, [checkoutData]);

  const onSubmit = async (
    values: FormValues,
    helpers: FormikHelpers<FormValues>,
  ) => {
    setLoading(true);

    try {
      const req = {
        addOnIds: values.selectedAddOns.map((addOn) => addOn.id),
        basePlanId: values.selectedPlan?.id,
      };

      await checkoutWithoutSubscription(company.id, req);

      await queryClient.invalidateQueries();

      onClose();

      setApiError(undefined);
      helpers.setSubmitting(false);
      onSuccess();
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  return (
    <Overlay onClose={onClose} className="flex items-center justify-center">
      {(loading || isCheckoutDataLoading) && <SchematicOverlayLoader />}
      <OverlayModal>
        <OverlayHeader
          label="Manage plan"
          title={company?.name}
          onClose={onClose}
        >
          <Logo src={company.logoUrl} alt={company.name} />
        </OverlayHeader>

        {initialValues && (
          <Formik
            enableReinitialize
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={Yup.object({
              selectedPlanId: Yup.string().required("Must provide a plan"),
              selectedAddOnIds: Yup.array(),
            })}
          >
            {({ values, setFieldValue, dirty }) => (
              <Form className="flex flex-col">
                <FormColumn className="px-12">
                  <FormRow>
                    <FormikAsyncSelect
                      className="flex-1"
                      label="Plan"
                      name="selectedPlanId"
                      description="Companies can only have one base plan"
                      placeholder="Type to select plan..."
                      defaultOptions
                      loadOptions={listPlansWithEntitlements}
                      loadOptionsMappers={{
                        mapperFunction: (plan) => ({
                          value: plan.id,
                          label: <PlanLabel plan={plan} font="normal" />,
                          entity: plan,
                        }),
                        requestFilter: {
                          withoutProductId: true,
                        },
                      }}
                      selectedOption={
                        values.selectedPlan && {
                          value: values.selectedPlan.id,
                          label: (
                            <PlanLabel
                              plan={values.selectedPlan}
                              font="normal"
                            />
                          ),
                          entity: values.selectedPlan,
                        }
                      }
                      onChange={async (option: PlanOption) => {
                        await setFieldValue(
                          "selectedPlan",
                          option?.entity || null,
                        );
                      }}
                    />
                  </FormRow>

                  <FormRow>
                    <FormikAsyncSelect
                      key="addons"
                      className="flex-1"
                      defaultOptions
                      label="Add Ons"
                      isMulti
                      description="Companies can have multiple add ons"
                      loadOptions={listAddOnsWithEntitlements}
                      loadOptionsMappers={{
                        mapperFunction: (addOn: Plan): AddOnOption => ({
                          name: addOn.name,
                          value: addOn.id,
                          label: <AddOnOptionLabel addOn={addOn} />,
                          resource: addOn,
                        }),
                        requestFilter: {
                          withoutProductId: true,
                        },
                      }}
                      name="selectedAddOnIds"
                      placeholder="Select add ons"
                      onChange={async (options: AddOnOption[]) => {
                        await setFieldValue(
                          "selectedAddOns",
                          options.map(({ resource }) => resource),
                        );
                      }}
                      selectedOption={values.selectedAddOns.map((addOn) => ({
                        name: addOn.name,
                        value: addOn.id,
                        label: <AddOnOptionLabel addOn={addOn} />,
                        resource: addOn,
                      }))}
                    />
                  </FormRow>
                </FormColumn>

                {apiError && (
                  <div className="pt-12 px-12">
                    <Alert size="xs" style="red">
                      <div className="flex items-center justify-center space-x-2">
                        <div className="text-base font-body">
                          <span className="font-semibold">Uh-oh!</span>{" "}
                          {apiError}
                        </div>
                      </div>
                    </Alert>
                  </div>
                )}

                <OverlayFooter className="px-12 pb-6">
                  <Button onClick={onClose}>Cancel</Button>
                  <Button type="submit" color="blue" disabled={!dirty}>
                    Save changes
                  </Button>
                </OverlayFooter>
              </Form>
            )}
          </Formik>
        )}
      </OverlayModal>
    </Overlay>
  );
};
