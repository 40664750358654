import SchematicOverlayLoader from "@components/loaders/SchematicOverlayLoader";
import { SinglePageLoader } from "@components/loaders/SinglePageLoader";
import { errorMessage } from "@data/index";
import { listIntegrations } from "@data/integrations";
import { useContextQuery } from "@hooks/useContextQuery";
import useSecondaryTableHeader from "@hooks/useSecondaryTableHeader";
import {
  PlanGroupPlanDetailResponseData,
  UpdatePlanGroupRequestBody,
} from "@models/api";
import { PlanType } from "@models/plan";
import { countPlans } from "@modules/plans";
import { ConnectStripeBreakdown } from "@modules/plans/components/ConnectStripeBreakdown";
import {
  DefaultPlanElevate,
  LiveAddOnsElevate,
  LivePlansElevate,
  TrialsConfigurationElevate,
} from "@modules/plans/components/elevates";
import { PlanChangeRules } from "@modules/plans/components/PlanChangeRules";
import { PlanRoutePaths } from "@modules/plans/consts";
import {
  createPlansConfiguration,
  getPlansConfiguration,
  updatePlansConfiguration,
} from "@modules/plans/queries/planConfiguration";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { TableHeader } from "@ui/TableHeader";
import { Toast } from "@ui/Toast";
import { ViewWrapper } from "@ui/ViewWrapper";
import { Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

export type PlansConfigurationViewFormValues = {
  addOns: PlanGroupPlanDetailResponseData[];
  addOnIds: string[];
  id?: string;
  plans: PlanGroupPlanDetailResponseData[];
  planIds: string[];
  defaultPlan?: PlanGroupPlanDetailResponseData;
  defaultPlanId?: string;
  trialPaymentMethodRequired: boolean;
  trialDays?: number;
};

const validationSchema = Yup.object().shape({
  plans: Yup.array().of(
    Yup.object()
      .shape({
        yearlyPrice: Yup.object(),
        monthlyPrice: Yup.object(),
      })
      .test(
        "monthlyPrice or yearlyPrice",
        "Either monthly or yearly plan price is required",
        ({ monthlyPrice, yearlyPrice }) => !!monthlyPrice || !!yearlyPrice,
      ),
  ),
  planIds: Yup.array().of(Yup.string()).min(0).max(100),
  defaultPlanId: Yup.string(),
  addOns: Yup.array().of(
    Yup.object()
      .shape({
        yearlyPrice: Yup.object(),
        monthlyPrice: Yup.object(),
      })
      .test(
        "monthlyPrice or yearlyPrice",
        "Either monthly or yearly add on price is required",
        ({ monthlyPrice, yearlyPrice }) => !!monthlyPrice || !!yearlyPrice,
      ),
  ),
});

export const PlansConfigurationView = () => {
  const { environmentId } = useParams() as {
    environmentId: string;
  };
  const queryClient = useQueryClient();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState<string | undefined>();
  const [toastOpen, setToastOpen] = useState(false);

  const planConfigurationFlag = useSchematicFlag("plan-configuration", {
    fallback: true,
  });

  const plansHeaderText = useSecondaryTableHeader(
    "plans",
    countPlans(PlanType.Plan),
  );
  const addonsHeaderText = useSecondaryTableHeader(
    "add ons",
    countPlans(PlanType.AddOn),
  );

  const onSubmit = async (
    {
      addOns,
      id,
      plans,
      defaultPlan,
      trialPaymentMethodRequired,
      trialDays,
    }: PlansConfigurationViewFormValues,
    helpers: FormikHelpers<PlansConfigurationViewFormValues>,
  ) => {
    setLoading(true);

    const req = {
      addOnIds: addOns.map((a) => a.id),
      planIds: plans.map((p) => p.id),
      defaultPlanId: defaultPlan?.id,
      trialPaymentMethodRequired: trialPaymentMethodRequired,
      trialDays: trialDays,
    };

    const saveFn = id
      ? (values: UpdatePlanGroupRequestBody) =>
          updatePlansConfiguration(id, values)
      : createPlansConfiguration;

    try {
      await saveFn(req);

      await queryClient.invalidateQueries();
      setApiError(undefined);
      helpers.setSubmitting(false);

      setToastOpen(true);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setApiError(errorMessage(error));
      helpers.setSubmitting(false);
      setLoading(false);
    }
  };

  const { data, isLoading } = useContextQuery({
    queryKey: ["plans", "configuration"],
    queryFn: async () => {
      try {
        return await getPlansConfiguration();
      } catch (error: any) {
        if (error.responseCode === 404) {
          return {
            addOns: [],
            addOnIds: [],
            id: undefined,
            defaultPlan: undefined,
            defaultPlanId: undefined,
            plans: [],
            planIds: [],
            trialDays: undefined,
            trialPaymentMethodRequired: false,
          };
        }

        throw error;
      }
    },
    retry: (failureCount, error: any) => {
      if (error.responseCode === 404) {
        return false;
      }

      return failureCount < 3;
    },
  });

  const { data: integrationsData, isLoading: isIntegrationsLoading } =
    useContextQuery({
      queryKey: ["integrations", "stripe"],
      queryFn: async () => {
        try {
          return await listIntegrations({ type: "stripe" });
        } catch (error: any) {
          // TODO: Fix integrations returning 500
          if (error.responseCode <= 500) {
            return [];
          }

          throw error;
        }
      },
      retry: (failureCount, error: any) => {
        // TODO: Fix integrations returning 500
        if (error.responseCode <= 500) {
          return false;
        }

        return failureCount < 3;
      },
    });

  const stripeConnected = integrationsData?.[0]?.state;

  if (isLoading || isIntegrationsLoading) {
    return <SinglePageLoader />;
  }

  const initialValues: PlansConfigurationViewFormValues = {
    addOns: data?.addOns || [],
    addOnIds: (data?.addOns || []).map((addOn) => addOn.id),
    id: data?.id,
    plans: data?.plans || [],
    planIds: (data?.plans || []).map((plan) => plan.id),
    defaultPlan: data?.defaultPlan,
    defaultPlanId: data?.defaultPlan?.id,
    trialPaymentMethodRequired: data?.trialPaymentMethodRequired || false,
    trialDays: data?.trialDays,
  };

  if (!planConfigurationFlag) {
    return;
  }

  return (
    <ViewWrapper>
      <Toast
        title="Plan configuration changes saved."
        open={toastOpen}
        setOpen={setToastOpen}
        position="center"
        duration={2000}
      />
      <div className="pb-16">
        {/*TODO: Maybe change to some slimmer loader*/}
        {loading && <SchematicOverlayLoader />}
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
          validateOnChange={false}
          enableReinitialize
        >
          {({ dirty, resetForm, submitForm }) => {
            return (
              <>
                <TableHeader
                  className="-mt-8 bg-[#FBFBFB] sticky top-[-3rem] left-0 z-20"
                  headerTabs={[
                    {
                      label: plansHeaderText,
                      url: `/${environmentId}/${PlanRoutePaths.Plans}`,
                    },
                    {
                      label: addonsHeaderText,
                      url: `/${environmentId}/${PlanRoutePaths.AddOns}`,
                    },
                    {
                      active: true,
                      label: "Configuration",
                      url: `/${environmentId}/${PlanRoutePaths.Configuration}`,
                    },
                  ]}
                  buttons={[
                    {
                      children: <>Cancel</>,
                      color: "white",
                      disabled: !dirty,
                      onClick: () => {
                        resetForm();
                      },
                    },
                    {
                      children: <>Save changes</>,
                      color: "blue",
                      disabled: !dirty,
                      onClick: () => {
                        submitForm();
                      },
                    },
                  ]}
                />

                <Form>
                  {apiError && (
                    <div className="px-2 mt-4">
                      <Alert size="xs" style="red">
                        <div className="flex items-center justify-center space-x-2">
                          <div className="text-base font-body ">
                            <span className="font-semibold">Uh-oh!</span>{" "}
                            {apiError}
                          </div>
                        </div>
                      </Alert>
                    </div>
                  )}

                  {!stripeConnected && <ConnectStripeBreakdown page="config" />}
                  <div className="flex flex-col space-y-8 pt-6">
                    <DefaultPlanElevate />
                    {stripeConnected && (
                      <>
                        <LivePlansElevate />
                        <LiveAddOnsElevate />
                        <TrialsConfigurationElevate />
                        <PlanChangeRules />
                      </>
                    )}
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      </div>
    </ViewWrapper>
  );
};
