import { Pill } from "@components/ui/Pill";
import { AddActiveAddOnsOverlay } from "@modules/plans/components/overlays";
import { PlanConfigurationCard } from "@modules/plans/components/PlanConfigurationCard";
import { PlansConfigurationViewFormValues } from "@modules/plans/components/PlansConfigurationView";
import { useSchematicFlag } from "@schematichq/schematic-react";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { Elevate } from "@ui/Elevate";
import { LabeledTooltip } from "@ui/LabeledTooltip";
import { useFormikContext } from "formik";
import { useState } from "react";

enum BilledPeriod {
  Monthly = "monthly",
  Yearly = "yearly",
}

export const LiveAddOnsElevate = () => {
  const { setFieldValue, values, touched, errors } =
    useFormikContext<PlansConfigurationViewFormValues>();
  const [addActiveAddOnsOverlay, setAddActiveAddOnsOverlay] = useState(false);
  const [billedPeriod, setBilledPeriod] = useState(BilledPeriod.Monthly);

  const monthlyAddOns = values.addOns
    .filter((addOn) => addOn.monthlyPrice)
    .sort((a, b) => a.monthlyPrice!.price - b.monthlyPrice!.price);
  const yearlyAddOns = values.addOns
    .filter((addOn) => addOn.yearlyPrice)
    .sort((a, b) => a.yearlyPrice!.price - b.yearlyPrice!.price);

  const isEnabled = useSchematicFlag("live-add-ons", { fallback: false });

  return (
    <Elevate>
      <div className="flex justify-between items-center mb-4">
        <div className="text-xl flex items-center">
          <span className="h-3 w-3 bg-emerald-300 rounded-full inline-block mr-2"></span>
          Live Add Ons
        </div>
        <div className="flex space-x-4">
          <LabeledTooltip
            description="Live Add Ons are visible in components and available for purchase."
            position="relative"
            placement="top-left"
            size="lg"
          />
          <div className="-space-x-4 flex border border-blue-100 rounded-3xl">
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Monthly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Monthly)}
            >
              {monthlyAddOns.length} Billed Monthly
            </button>
            <button
              type="button"
              className={`px-8 py-2 rounded-3xl hover:cursor-pointer hover:bg-slate-200 ${
                billedPeriod === BilledPeriod.Yearly &&
                "bg-gray-200 font-semibold"
              }`}
              onClick={() => setBilledPeriod(BilledPeriod.Yearly)}
            >
              {yearlyAddOns.length} Billed Yearly
            </button>
          </div>
        </div>
      </div>

      {!isEnabled && (
        <Alert size="lg" style="light-gray" background="schematic">
          <div className="flex justify-center items-center">
            <Button
              color="white"
              type="button"
              onClick={() => {
                setAddActiveAddOnsOverlay(true);
              }}
            >
              <div className="flex items-center gap-2">
                Add Live Add Ons
                <Pill type="tag" color="gray">
                  Upgrade
                </Pill>
              </div>
            </Button>
          </div>
        </Alert>
      )}

      {((isEnabled &&
        billedPeriod === BilledPeriod.Monthly &&
        monthlyAddOns.length === 0) ||
        (billedPeriod === BilledPeriod.Yearly &&
          yearlyAddOns.length === 0)) && (
        <Alert size="lg" style="light-gray" background="schematic">
          <div className="flex justify-center items-center">
            <Button
              color="blue"
              type="button"
              onClick={() => {
                setAddActiveAddOnsOverlay(true);
              }}
            >
              Add Add Ons
            </Button>
          </div>
        </Alert>
      )}

      {isEnabled &&
        billedPeriod === BilledPeriod.Monthly &&
        monthlyAddOns.length > 0 && (
          <div className="flex mt-6 relative">
            <div className="relative overflow-x-hidden grow">
              <div className="flex grow space-x-4 overflow-x-scroll">
                {monthlyAddOns.map((addOn) => (
                  <PlanConfigurationCard
                    plan={addOn}
                    key={addOn.id}
                    onClose={async () => {
                      const newAddOnIds = values.addOnIds.filter(
                        (item) => item !== addOn.id,
                      );
                      const newAddOns = values.addOns.filter(
                        (item) => item.id !== addOn.id,
                      );
                      await setFieldValue("addOnIds", newAddOnIds);
                      await setFieldValue("addOns", newAddOns);
                    }}
                  />
                ))}
              </div>
            </div>
            <Button
              type="button"
              className="relative right-0 top-0 bottom-0 !text-3xl"
              color="white"
              onClick={() => {
                setAddActiveAddOnsOverlay(true);
              }}
            >
              +
            </Button>
          </div>
        )}

      {isEnabled &&
        billedPeriod === BilledPeriod.Yearly &&
        yearlyAddOns.length > 0 && (
          <div className="flex mt-6 relative">
            <div className="relative overflow-x-hidden grow">
              <div className="flex grow space-x-4 overflow-x-scroll">
                {yearlyAddOns.map((addOn) => (
                  <PlanConfigurationCard
                    plan={addOn}
                    key={addOn.id}
                    onClose={async () => {
                      const newAddOnIds = values.addOnIds.filter(
                        (item) => item !== addOn.id,
                      );
                      const newAddOns = values.addOns.filter(
                        (item) => item.id !== addOn.id,
                      );
                      await setFieldValue("addOnIds", newAddOnIds);
                      await setFieldValue("addOns", newAddOns);
                    }}
                  />
                ))}
              </div>
            </div>
            <Button
              type="button"
              className="relative right-0 top-0 bottom-0 !text-3xl"
              color="white"
              onClick={() => {
                setAddActiveAddOnsOverlay(true);
              }}
            >
              +
            </Button>
          </div>
        )}

      {isEnabled &&
        touched.addOnIds &&
        errors.addOns &&
        errors.addOns.length > 0 && (
          <Alert size="xs" style="red" className="mt-4">
            <div className="flex items-center justify-center space-x-2">
              <div className="text-base font-body ">
                <span className="font-semibold">Uh-oh!</span> All add ons should
                have set-up default monthly or yearly price
              </div>
            </div>
          </Alert>
        )}

      {isEnabled && addActiveAddOnsOverlay && (
        <AddActiveAddOnsOverlay
          selectedAddOns={values.addOns}
          setActiveAddOns={async (addOns) => {
            const addOnIds = addOns.map((addOn) => addOn.id);

            await setFieldValue("addOns", addOns);
            await setFieldValue("addOnIds", addOnIds);
          }}
          onClose={() => {
            setAddActiveAddOnsOverlay(false);
          }}
        />
      )}
    </Elevate>
  );
};
