import { environmentPillColors } from "@components/overlays/types";
import { Toast } from "@components/ui/Toast";
import { EnvironmentTypeShort } from "@consts/environments";
import * as api from "@data/environments";
import { errorMessage } from "@data/index";
import { FormikControl } from "@forms/FormikControl";
import { FormikSelect } from "@forms/FormikSelect";
import { useRole } from "@hooks/useRole";
import { AccountEnvironment, EnvironmentType } from "@models/account";
import { CreateEnvironmentRequestBody } from "@models/api";
import { ClerkUserRole } from "@models/clerkUser";
import { SettingsPage } from "@modules/settings/consts";
import { useQueryClient } from "@tanstack/react-query";
import { Alert } from "@ui/Alert";
import { Button } from "@ui/Button";
import { FormColumn } from "@ui/FormParts";
import { Icon } from "@ui/Icon";
import { Pill, PillStyleTypes } from "@ui/Pill";
import cx from "classnames";
import { Form, Formik } from "formik";
import { useState } from "react";
import { NavLink, useParams } from "react-router-dom";
import * as Yup from "yup";

export interface EnvironmentOverlayFormProps {
  environment?: AccountEnvironment;
  onDelete: (environment: AccountEnvironment) => Promise<void>;
  onCreate: (environment: AccountEnvironment) => void;
  setLoading: (loading: boolean) => void;
  onClose: () => void;
}

export const EnvironmentOverlayForm = ({
  environment,
  onDelete,
  onCreate,
  setLoading,
  onClose,
}: EnvironmentOverlayFormProps) => {
  const { environmentId } = useParams() as { environmentId: string };
  const [apiError, setApiError] = useState<string | undefined>();
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [apiSuccess, setApiSuccess] = useState<string>("");
  const environmentEditAllowed = useRole(ClerkUserRole.Admin);
  const [toastOpen, setToastOpen] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const initialValues = {
    name: environment?.name || "",
    environmentType:
      environment?.environmentType || EnvironmentType.Development,
  };

  const handleDeleteAlert = () => {
    setDeleteAlert(true);
  };

  const handleDelete = async () => {
    if (!environment) return;
    setLoading(true);

    try {
      await onDelete(environment);
      setLoading(false);
      setDeleteAlert(false);
      setApiError(undefined);
      setApiSuccess("Environment deleted");
      setToastOpen(true);
    } catch (e) {
      setDeleteAlert(false);
      setLoading(false);
      setApiError(errorMessage(e));
      setToastOpen(true);
    }

    queryClient.invalidateQueries();
  };

  const onSubmit = async (values: CreateEnvironmentRequestBody) => {
    setLoading(true);
    const saveFn = environment?.id
      ? (values: CreateEnvironmentRequestBody) =>
          api.updateEnvironment(environment.id, values)
      : api.createEnvironment;

    try {
      const environment = await saveFn(values);

      await queryClient.invalidateQueries();
      onCreate(environment);
      setApiError(undefined);
      setLoading(false);
      setApiSuccess("Environment saved");
      setToastOpen(true);
    } catch (error) {
      console.error(error);
      setApiSuccess("");
      setToastOpen(true);
      setLoading(false);
      setApiError(errorMessage(error));
    }
  };
  environment?.environmentType;

  return (
    <div className="flex  flex-col w-full p-8 overflow-y-auto">
      <div className="mb-12 space-y-4">
        <div className="text-2xl">
          {environment?.name ? (
            <div className="flex space-x-1.5">
              <div>Edit</div>
              <div className="flex space-x-1.5 items-center">
                <div className="font-medium">{environment?.name}</div>
                <div className="-mt-1.5">
                  <Pill
                    color={
                      environmentPillColors[
                        EnvironmentType.Production
                      ] as PillStyleTypes
                    }
                  >
                    {EnvironmentTypeShort[EnvironmentType.Production]}
                  </Pill>
                </div>
              </div>
            </div>
          ) : (
            "Create environment"
          )}
        </div>
        <div className="text-base">
          {environment?.name
            ? "Edit your environment to control your feaeture flags"
            : "Environments are used to control the deployment of your feature flags"}
        </div>

        {environment == undefined && (
          <Alert style="yellow" size="xs" className="flex text-sm ">
            <div className="mr-3">
              <Icon
                name="exclamation-rounded-filled"
                className="text-2xl leading-none text-yellow-300"
              />
            </div>
            <div>
              Only admins will have access to this environment by default.{" "}
              <NavLink
                to={`/${environmentId}/settings/${SettingsPage.Team}`}
                className="text-blue-400"
                onClick={() => {
                  onClose();
                }}
              >
                Enable permissions for members
              </NavLink>{" "}
              to grant them access.
            </div>
          </Alert>
        )}
      </div>

      <div className="flex-1">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            name: Yup.string().required("Must provide environment name"),
            environmentType: Yup.string().required(
              "Must select environment type",
            ),
          })}
        >
          {(form) => {
            return (
              <Form className="flex  flex-col h-full">
                <FormColumn className="flex-1">
                  <FormikControl
                    control="input"
                    label="Environment name"
                    name="name"
                    placeholder="Enter environment name"
                    type="text"
                  />

                  <FormikSelect
                    label="Type"
                    name="environmentType"
                    options={[
                      {
                        value: EnvironmentType.Production,
                        label: (
                          <div className="space-x-2 flex flex-row items-center">
                            <div>Production</div>
                            <Pill
                              color={
                                environmentPillColors[
                                  EnvironmentType.Production
                                ] as PillStyleTypes
                              }
                            >
                              {EnvironmentTypeShort[EnvironmentType.Production]}
                            </Pill>
                          </div>
                        ),
                      },
                      {
                        value: EnvironmentType.Staging,
                        label: (
                          <div className="space-x-2 flex flex-row items-center">
                            <div>Staging</div>
                            <Pill
                              color={
                                environmentPillColors[
                                  EnvironmentType.Staging
                                ] as PillStyleTypes
                              }
                            >
                              {EnvironmentTypeShort[EnvironmentType.Staging]}
                            </Pill>
                          </div>
                        ),
                      },
                      {
                        value: EnvironmentType.Development,
                        label: (
                          <div className="space-x-2 flex flex-row items-center">
                            <div>Development</div>
                            <Pill
                              color={
                                environmentPillColors[
                                  EnvironmentType.Development
                                ] as PillStyleTypes
                              }
                            >
                              {
                                EnvironmentTypeShort[
                                  EnvironmentType.Development
                                ]
                              }
                            </Pill>
                          </div>
                        ),
                      },
                    ]}
                  />

                  <Toast
                    title={apiSuccess || apiError}
                    open={toastOpen}
                    setOpen={setToastOpen}
                    duration={3000}
                  />

                  {/* {apiError && (
                    <Alert size="xs" style="red">
                      <span className="font-semibold">Uh-oh!</span> {apiError}
                    </Alert>
                  )}

                  {apiSuccess && (
                    <Alert size="xs" style="green">
                      <div className="text-base font-body ">{apiSuccess}</div>
                    </Alert>
                  )} */}
                </FormColumn>

                <div
                  className={cx(
                    "flex items-end space-x-2 flex-1 pt-4",
                    environment?.id ? "justify-between " : "justify-end",
                  )}
                >
                  {environment?.id && (
                    <Button
                      color="red"
                      onClick={handleDeleteAlert}
                      disabled={!environmentEditAllowed}
                      size="sm"
                      type="button"
                    >
                      Delete
                    </Button>
                  )}

                  <Button
                    type="submit"
                    color="blue"
                    disabled={!environmentEditAllowed || !form.dirty}
                  >
                    {environment == undefined
                      ? "Create environment"
                      : "Save changes"}
                  </Button>
                </div>

                {deleteAlert && (
                  <div className="absolute left-0 top-0 w-full h-full bg-black/20 items-center flex justify-center shadow-md">
                    <div className="bg-white rounded-lg p-8 space-y-4 flex flex-col items-center text-center">
                      <div className="text-2xl leading-6 font-medium">
                        Are you sure you want to delete this environment?
                      </div>
                      <div>
                        Deleting this environment is permanent and irreversible
                      </div>
                      <div className="pt-6 space-x-4">
                        <Button
                          color="blue"
                          onClick={() => setDeleteAlert(false)}
                          size="md"
                          type="button"
                        >
                          No, go back
                        </Button>
                        <Button
                          color="red"
                          onClick={handleDelete}
                          size="md"
                          type="button"
                        >
                          Yes, delete environment
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
